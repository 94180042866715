<template>
  <div>
    <!-- TODO:名单粘贴 -->
    <van-dialog
      className="name_paste_dialog"
      v-model="namePasteDialogShow"
      :show-confirm-button="false"
      title="名单粘贴"
    >
      <van-field
        class="name_textarea"
        v-model.trim="nameString"
        rows="8"
        autosize
        type="textarea"
        border
        autofocus
        placeholder="请按规则输入名单数据，姓名中间存在多个空格会解析失败。
正确规则如：
	张三  身份证  110101199901017917
	张红  女  E12345678  1980-01-10"
      ></van-field>
      <p v-if="$store.state.cps_productId == 27555" class="error">
        *证件类型只能为身份证
      </p>
      <div class="btns">
        <van-button type="default" @click="namePasteDialogShow = false"
          >取消</van-button
        >
        <van-button
          type="info"
          @click="analysisNameStr"
          :disabled="!nameString"
          :loading="analysisIng"
          loading-text="解析中..."
          >确定</van-button
        >
      </div>
    </van-dialog>
    <!-- TODO:错误提示 -->
    <van-dialog
      className="name_paste_dialog error_name_dialog"
      v-model="namePasteErrorDialogShow"
      :show-confirm-button="false"
      title="错误提示"
    >
      <p class="statistics">
        解析
        <span class="total_num num" v-text="nameSuccessLength + nameErrorLength"
          >0</span
        >
        条数据，成功
        <span class="success_num num" v-text="nameSuccessLength">0</span>
        条，失败
        <span class="error_num num" v-text="nameErrorLength">0</span>
        条
      </p>
      <p class="title">解析失败数据：</p>
      <van-field
        class="name_textarea"
        v-model="errorNameString"
        rows="8"
        autosize
        type="textarea"
      ></van-field>
      <p class="error">可能的错误原因：输入不规范、证件号码错误</p>
      <div class="btns">
        <van-button type="default" @click="namePasteErrorDialogShow = false"
          >关闭</van-button
        >
        <van-button
          type="info"
          ref="copyBtn"
          :data-clipboard-text="errorNameString"
          >复制错误信息</van-button
        >
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Toast, Dialog, Field, Button } from "vant";
import { analysisNameString } from "@/request/api";
import ClipboardJS from "clipboard";
import { http_insTranslate } from '@/request/common';
export default {
  components: {
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Button.name]: Button,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataLength: {
      type: Number,
      default: 0,
    },
    hasEnglishName:{
      type:Boolean
    }
  },

  data() {
    return {
      analysisIng: false,
      namePasteErrorDialogShow: false,
      nameSuccessLength: 0,
      nameErrorLength: 0,
      errorNameString: "",
      nameString: "",
    };
  },
  computed: {
    namePasteDialogShow: {
      get() {
        return this.show;
      },
      set(val) {
        if (!val) {
          this.nameString = "";
        }
        this.$emit("update:show", val);
      },
    },
  },
  methods: {
    // 解析名单
    analysisNameStr() {
      let rows = this.nameString.split("\n");

      // 200条数据检测
      if (rows.length > 200) {
        this.analysisToast("最大支持200条数据，请修改输入的数据", "fail");
      }

      // 去除姓名中间的空格，只支持一个空格
      let nameString = this.removeIndex1Space(rows);

      this.analysisIng = true;
      analysisNameString(nameString).then(async (res) => {
        this.analysisIng = false;
        this.nameSuccessLength = res.profList.length;
        this.nameErrorLength = res.errorList.length;

        // 解析结果中有错误信息
        if (this.nameErrorLength) {
          this.errorNameString = "";
          res.errorList.forEach((itemStr) => {
            this.errorNameString += itemStr + "\n";
          });
          this.namePasteErrorDialogShow = true;

          // 初始化复制事件
          this.$nextTick(this.copyEventInit);
        }

        // 最多只解析200条信息
        let list = res.profList;
        if (this.dataLength + list.length > 200) {
          list.splice(200 - this.dataLength);
        }

        if (this.$store.state.cps_productId == 27555) {
          let errInfoName = "";
          list = list.filter((item) => {
            if (item.certificateType == "01") {
              return item;
            } else {
              errInfoName += item.name;
            }
          });
          if (errInfoName) {
            Toast(errInfoName + "证件号码只能为身份证");
          }
        }

        // 解析结果到被保险人名单中
        if (list.length) {
          let englishNameData={}
          if(this.hasEnglishName){
            const allEnglishName=list.map((item=>item.name))
            englishNameData=await http_insTranslate(allEnglishName)
				  }
          list.forEach((item, index) => {
            this.$emit(
              "setUserInfo",
              {
                name: String(item.name).replace(" ", ""),
                certificateType: item.certificateType,
                englishName: this.hasEnglishName?englishNameData[item.name]:'',
                number: item.certificateContent.toLocaleUpperCase(),
                birthday: item.birthday,
                sex: item.sex,
                phone: item.mobile,
                id: undefined,
              },
              index
            );
          });
        }

        this.namePasteDialogShow = false;
      });
    },

    analysisToast(msg, type = "text") {
      Toast({
        message: msg,
        className: "analysis_toast",
        getContainer: "#create_order",
        type,
      });
    },
    // 把textarea中取的值，处理每行前2个字符中的空格（为了处理姓名中间的空格），并返回textarea格式的字符串
    removeIndex1Space(rows) {
      let result = "";
      rows.forEach((row) => {
        let trimRow = row.trim();

        // 去除特殊的字符
        trimRow = this.removeSpecialString(trimRow);

        if (trimRow.slice(0, 2).includes(" ")) {
          trimRow = trimRow.replace(" ", "");
        }
        result = result + trimRow + "\n";
      });
      return result;
    },
    // 去除特殊字符
    removeSpecialString(str) {
      let strCode = encodeURI(str);
      let specialCodeList = ["%E2%80%8B"];
      specialCodeList.forEach((code) => {
        strCode = strCode.replace(code, "");
      });
      return decodeURI(strCode);
    },
    // 初始化复制事件
    copyEventInit() {
      let clipboard = new ClipboardJS(this.$refs.copyBtn);
      clipboard.on("success", (e) => {
        this.analysisToast("复制成功", "success");
        e.clearSelection();
      });

      clipboard.on("error", (e) => {
        this.analysisToast("复制成功", "fail");
      });
    },

    // 手动校验被保险人信息
    chekUserInfoList() {
      this.userList.forEach((dict) => {
        dict.isError = !this.checkUserInfo(dict);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .name_paste_dialog {
  z-index: 3002 !important;

  .name_textarea {
    textarea {
      max-height: 50vh;
      overflow-y: auto;
      // background-color: $line_color;
      border: 1px solid $line_color;
      padding: 0.08rem;
    }
  }

  .van-cell::after {
    border: none;
  }

  .btns {
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
    padding: 0 0.3rem 10px;

    button {
      width: 42%;
    }
  }

  .van-dialog__cancel {
    color: #323233;
    background-color: #fff;
    background: #fff;
    border: 1px solid #ebedf0;
    border-radius: 2px;
    margin-right: 0.1rem;
  }

  .van-dialog__confirm {
    border-radius: 2px;
    margin-left: 0.1rem;
  }
}

::v-deep .van-overlay {
  z-index: 3001 !important;
}

::v-deep .analysis_toast {
  z-index: 3003 !important;
}

.error_name_dialog {
  .statistics {
    padding: 0.3rem;
    font-weight: bold;
    font-size: 14px;
  }

  .num {
    font-size: 20px;
  }

  .error_num {
    color: $danger;
  }

  .title {
    padding: 0 0.3rem;
    font-size: 14px;
    line-height: 14px;
    position: relative;
    top: 6px;
    z-index: 1;
  }

  .error {
    color: $danger;
    font-size: 12px;
    line-height: 14px;
    position: relative;
    top: -6px;
    padding: 0 0.3rem;
    margin-bottom: 0.2rem;
  }
}
.error {
  color: $danger;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  top: -6px;
  padding: 0 0.3rem;
  margin-bottom: 0.2rem;
}
</style>
